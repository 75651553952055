import axios from 'axios';

function call(){
  return axios.create({
    baseURL: `/api/v1/`,
    responseType: "json"
  });
} 

export function apiClient(endpoint,method,params){

  const headers = {'content-type': 'application/json'}
  const token = localStorage.getItem('__token__')
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const config = {
    method: method,
    headers: headers
  }

  if(params){
    config.data = params
  }

  console.log(endpoint)

  return call()(endpoint, config)
    .then(function (response) {

    // handle success
    return response.data.data
  })
  .catch(function (error) {

    // handle error
   
    if (error.response.status === 422) {

      const throwError = new Error('Failed in some way');
      
      throwError.response = error.response.data;

      throw throwError;

    }

    // if (error.response) {
    //   // The request was made and the server responded with a status code
    //   // that falls out of the range of 2xx
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   // The request was made but no response was received
    //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //   // http.ClientRequest in node.js
    //   console.log(error.request);
    // } else {
    //   // Something happened in setting up the request that triggered an Error
    //   console.log('Error', error.message);
    // }
    // console.log(error.config);



    console.log('base api error:', error);

    throw new Error(error);
  })

}


