import React,{lazy} from 'react';
import {useUser} from './context/user'
import { Layout, Spin } from 'antd';

const AuthenticatedApp = lazy(() => import('./authenticated'))
const UnauthenticatedApp = lazy(() => import('./unauthenticated'))


export default function App() {
  
  const user = useUser()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <React.Suspense fallback={<div style={{display:'flex',flex:1, justifyContent:'center', alignItems:'center'}}><Spin /></div>}>
        { user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </Layout>
  );
  
}