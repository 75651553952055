import React, {useState,useEffect} from 'react'
import * as authClient from '../utils/auth'
import { Layout,Spin } from 'antd';

const AuthContext = React.createContext()


function AuthProvider(props) {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

      async function fetchUser() {
          setLoading(true);
          const response = await authClient.getUser();
          setLoading(false);
          setUser(response);
      }
      
      fetchUser();

  },[]);

  if(loading){
    return (
    <Layout style={{ minHeight: '100vh' }}>
      <div style={{display:'flex',flex:1, justifyContent:'center', alignItems:'center'}}>
        <Spin />
      </div>
    </Layout>)
  }

  const login = form => authClient.performLogin(form)
  .then(user => setUser(user))
  .catch(() => {
    return Promise.reject(null)
  })
  
  const logout = () => authClient.performlogout().then(setUser(null))

  return (
    <AuthContext.Provider value={{user, login, logout}} {...props} />
  )

}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export {AuthProvider, useAuth}