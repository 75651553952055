import {apiClient} from './base'

export function login({email,password}){

    const data = {
        email:email,
        password:password
    };

    return apiClient('login','post',data)

}

export function user(){

    return apiClient('user','get')

}

export function uploadPicture(params) {

    const data = {
        imageUrl: params.image,
    }

    console.log('data',data)

    return apiClient('user/upload-picture', 'post', data)

}

export function getInviteUser(token){

    const data = {
        token
    };

    return apiClient('invited-user','post',data)

}

export function setPasswordInviteUser(params){

    const data = {
        token: params.token,
        email: params.email,
        password: params.password,
        c_password: params.c_password
    };

    return apiClient('invited-set-password','post',data)

}


export function reset(params){

    const data = {
        email: params.email
    };

    return apiClient('forgot/password','post',data)

}

export function getResetToken(token){

    return apiClient('reset/password/' + token ,'get')

}

export function updatePassword(params){

    const data = {
        email: params.email,
        password: params.password,
        password_confirmation: params.password_confirmation,
        token: params.token
    };

    return apiClient('reset/password','post',data)

}