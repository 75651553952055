import {login,user} from '../api/auth'

const localStorageKey = '__token__'

function handleUserResponse(response) {

  if(!response) return null
  if(!response.token) return null
  localStorage.setItem(localStorageKey, response.token)

  return response.user
  
}

function getUser() {

  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }
  return user().catch(error => {
    performlogout()
    return Promise.reject(error)
  })

}

function performLogin(form) {
  return login(form)
  .then(response => {
    if(!response) return Promise.reject(null)
    return response
  })
  .then(handleUserResponse)
  .catch(() => {
    return Promise.reject(null)
  })
}

function performlogout() {
  localStorage.removeItem(localStorageKey)
  return Promise.resolve()
}

function getToken() {
  return localStorage.getItem(localStorageKey)
}

export {performLogin, performlogout, getToken, getUser}